// React imports

import type { RouteMatch } from '@remix-run/react'
import { useEffect } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports

const useCheckForLeafRouteOnlyData = (matches: RouteMatch[]) => {
  useEffect(() => {
    matches.forEach((item, index) => {
      const dataKeys = Object.keys(item.data || {})
      if (index === 0) {
        if (dataKeys.length > 2) {
          console.warn(
            `WARNING: root route loader is returning data! We expect only sentryTrace and setryBaggage but we got: ${dataKeys}`
          )
        }
      }

      if (index !== 0 && index !== matches.length - 1) {
        if (item.data) {
          console.warn(
            `WARNING: loader for non-leadf route ${item.id} is returning data: ${dataKeys}`
          )
        }
      }
    })
  }, [matches])
}

export default useCheckForLeafRouteOnlyData
