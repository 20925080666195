// React imports
import { useLocation } from '@remix-run/react'
import { useEffect, useState } from 'react'
import useGlobal from '~/hooks/useGlobal'

// Remix imports

// Third party imports

// Generated imports

// App imports
import useUserData from '~/hooks/useUserData'
import { pageview } from '~/utils/analytics'

const GTM: React.FC = () => {
  const global = useGlobal()
  const userData = useUserData()
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (!isMounted && userData?.hasInteracted) {
      setIsMounted(true)
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${global.ENV.GTM_ID}');`
      document.head.appendChild(s)

      const setDataLayer = {} as any
      if (userData.currentProfile?.user) {
        setDataLayer.userId = userData.currentProfile.user.id
        setDataLayer.userProfileType = userData.currentProfile.userProfileType
      } else {
        setDataLayer.userProfileType = 'isAnonymous'
      }

      const s2 = document.createElement('script')
      s2.type = 'text/javascript'
      s2.async = true
      s2.innerHTML = `
      window.dataLayer = window.dataLayer || []; 
      window.dataLayer.push(${JSON.stringify(setDataLayer)});
      `
      document.head.appendChild(s2)
    }
  }, [userData, isMounted, global])

  const location = useLocation()
  useEffect(() => {
    if (global.ENV.GTM_ID && userData?.hasInteracted) {
      pageview()
    }
  }, [location, global.ENV.GTM_ID, userData?.hasInteracted])

  return null
}

export default GTM
