// React imports

// Remix imports
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useMatches } from
'@remix-run/react';
import type { LinksFunction } from '@remix-run/server-runtime';

// Third party imports
import { IKContext } from 'imagekitio-react';
import isEmpty from 'lodash/isEmpty';

// Generated imports
import blogContentCssUrl from './styles/blogContent.css';
import tailwindStylesheetUrl from './styles/tailwind.css';

// App imports
// import { withSentry } from '@sentry/remix'
import Crisp from '~/components/misc/Crisp';
import GlobalLoading from '~/components/misc/GlobalLoading';
import Modals, { ModalsContextProvider } from '~/components/modals/Modals';
import { UserDataContextProvider } from '~/components/performance/UserDataContextProvider';
import GTM from '~/components/tracking/GTM';
import useCheckForLeafRouteOnlyData from '~/hooks/useCheckForLeafRouteOnlyData';
import useGlobal from '~/hooks/useGlobal';
import useLang from '~/hooks/useLang';
import appleTouchIconPngUrl from '~/static/apple-touch-icon.png';
import favicon16PngUrl from '~/static/favicon-16x16.png';
import favicon32PngUrl from '~/static/favicon-32x32.png';
import faviconIcoUrl from '~/static/favicon.ico';
import safariPinnedTabSvgUrl from '~/static/safari-pinned-tab.svg';

// function Document({ children }: { children: React.ReactNode }) {
//   return (
//     <html
//       lang="en"
//       className="overscroll-y-scroll h-full"
//     >
//       <head>
//         <Meta />
//         <meta
//           name="viewport"
//           content="width=device-width, initial-scale=1"
//         />
//         <Links />
//       </head>
//       <body className="h-full overflow-y-scroll">
//         {children}
//         <Scripts />
//         <LiveReload />
//       </body>
//     </html>
//   )
// }

// // ============================================================================
// // ERROR ======================================================================
// // ============================================================================
// export function ErrorBoundary({ error }: { error: Error }) {
//   const { lang } = useParams()

//   return (
//     <Document>
//       <div className="flex h-full flex-col justify-center text-center">
//         <Link
//           to={`/${lang}/`}
//           prefetch="intent"
//           className="cursor-pointer"
//         >
//           <img
//             src={TheArtlingLogoUrl}
//             alt=""
//             className="mx-auto mt-4 h-[60px] w-[60px] lg:w-[80px] "
//           />
//         </Link>
//         <div className="my-auto">
//           <H1 className="mb-8">Server Error</H1>
//           <P1>Oops! Something went wrong.</P1>
//           <P1>
//             We are currently trying to fix the problem. Please refresh the page
//             after a few minutes.
//           </P1>
//         </div>
//       </div>
//     </Document>
//   )
// }

// ============================================================================
// HTML =======================================================================
// ============================================================================
function Root() {
  // WARNING: You cannot use useUserData here, since it is outside of
  // <UserDataContextProvider>
  const matches = useMatches();
  useCheckForLeafRouteOnlyData(matches);
  const lang = useLang();
  const { ENV, data } = useGlobal();

  return (
    <UserDataContextProvider>
      <html
        lang={lang}
        className="overscroll-y-scroll h-full">

        <head>
          <Meta />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1" />

          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(ENV)}`
            }} />


          {ENV.GTM_ID && <GTM />}
          {!isEmpty(data.canonical) &&
          <link
            rel="canonical"
            href={data.canonical} />}


          {!isEmpty(data.relPrev) &&
          <link
            rel="prev"
            href={(data.relPrev as string)} />}


          {!isEmpty(data.relNext) &&
          <link
            rel="next"
            href={(data.relNext as string)} />}


          <Links />
        </head>
        <IKContext urlEndpoint={ENV.IMAGEKIT_PATH}>
          <ModalsContextProvider>
            <body className="h-full overflow-x-hidden overflow-y-scroll bg-brand-bg">
              {ENV.GTM_ID &&
              <noscript>
                  <iframe
                  title="gtm"
                  src={`https://www.googletagmanager.com/ns.html?id=${ENV.GTM_ID}`}
                  height="0"
                  width="0"
                  style={{ display: 'none', visibility: 'hidden' }}>
                </iframe>
                </noscript>}


              <div className="h-full">
                <GlobalLoading />
                <Outlet />
              </div>
              <div className="modal">
                <Modals />
              </div>
              <ScrollRestoration />
              <Crisp />
              <Scripts />
              <LiveReload />
            </body>
          </ModalsContextProvider>
        </IKContext>
      </html>
    </UserDataContextProvider>);

}

// export default withSentry(Root)
export default Root;

// ============================================================================
// LINKS ======================================================================
// ============================================================================
export const links: LinksFunction = () => {
  return [
  { rel: 'stylesheet', href: tailwindStylesheetUrl },
  { rel: 'stylesheet', href: blogContentCssUrl },
  { rel: 'preconnect', href: 'fonts.gstatic.com', crossOrigin: 'anonymous' },
  { rel: 'preconnect', href: 'fonts.googleapis.com' },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap'
  },
  { rel: 'shortcut icon', href: faviconIcoUrl, type: 'image/x-icon' },
  { rel: 'icon', href: favicon32PngUrl, sizes: '32x32' },
  { rel: 'icon', href: favicon16PngUrl, sizes: '16x16' },
  { rel: 'apple-touch-icon', href: appleTouchIconPngUrl, sizes: '180x180' },
  { rel: 'mask-icon', href: safariPinnedTabSvgUrl, color: '#2b2b2b' }];

};