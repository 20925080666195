// React imports
import { useEffect, useState } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports
import useIsMobile from '~/hooks/useIsMobile'
import useIsMounted from '~/hooks/useIsMounted'
import useUserData from '~/hooks/useUserData'

// ============================================================================
// HTML =======================================================================
// ============================================================================
const Crisp: React.FC = () => {
  const { isMounted: mounted } = useIsMounted()
  const [isMounted, setIsMounted] = useState(false)
  const { isMobile } = useIsMobile()
  const userData = useUserData()
  const profile = userData?.currentProfile
  const cartId = userData?.cart?.id

  useEffect(() => {
    if (!mounted) {
      return
    }
    window.$crisp = window.$crisp || []
    window.$crisp.push(['safe', true])
    window.CRISP_WEBSITE_ID = window.ENV.CRISP_WEBSITE

    const crispFunction = () => {
      const d = document
      const s: any = d.createElement('script')

      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    }
    crispFunction()
    setIsMounted(true)
  }, [mounted])

  useEffect(() => {
    if (isMounted) {
      const sessionData = []
      if (profile) {
        sessionData.push(['userId', `${profile.user?.id}`])
        sessionData.push(['userProfileType', `${profile.userProfileType}`])
        // Crisp identity verification
        // See https://help.crisp.chat/en/article/how-to-verify-user-identity-with-email-signatures-166sl01/
        window.$crisp.push([
          'set',
          'user:email',
          [profile.user?.email, profile.userHash],
        ])
      } else {
        sessionData.push(['userProfileType', `isAnonymous`])
      }

      if (cartId) {
        sessionData.push(['cartId', `${cartId}`])
      }

      if (sessionData.length > 0) {
        window.$crisp.push(['set', 'session:data', [sessionData]])
      }

      if (isMobile) {
        window.$crisp.push(['do', 'chat:hide'])
        window.$crisp.push([
          'on',
          'chat:closed',
          () => window.$crisp.push(['do', 'chat:hide']),
        ])
      }
    }
  }, [isMounted, profile, cartId, isMobile])

  return null
}

export default Crisp
